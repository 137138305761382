<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(create)">
        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <market-place-form :marketPlaceData="marketPlaceData" />
      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BForm, BButton } from "bootstrap-vue";
import { onUnmounted, ref } from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";
import { required } from "@validations";
import store from "@/store";
import router from "@/router";
import MarketPlaceForm from "../marketPlace-form/MarketPlaceForm.vue";
import useMarketPlaceForm from "../marketPlace-form/useMarketPlaceForm";
import marketPlaceStore from "../marketPlaceStore";
import clearForm from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    //  Form Validation
    ValidationObserver,
    MarketPlaceForm,
  },
  data() {
    return {
      required,
    };
  },

  setup() {
   const DEVICE_APP_STORE_MODULE_NAME = "app-device";

    // Register module
    if (!store.hasModule(DEVICE_APP_STORE_MODULE_NAME))
      store.registerModule(DEVICE_APP_STORE_MODULE_NAME, marketPlaceStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEVICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(DEVICE_APP_STORE_MODULE_NAME);
    });

    let { marketPlaceData } = useMarketPlaceForm();

    async function createMarketPlace() {
      try {
        let newdevice = marketPlaceData.value;
        const response = await store.dispatch(
          "app-device/createDevice",
          newdevice
        );

        if (response.data.status) {
          router.push({ name: "general-settings-deviceMarketPlace-list" });
        }
      } catch (error) {
        console.error(error);
      }
    }

    async function create() {
      try {
        createMarketPlace();
      } catch (error) {
        console.error(error);
      }
    }

    return {
      //  Data
      marketPlaceData,

      //  Action
      create,
    };
  },
};
</script>
