import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useMarketPlaceForm() {
  const marketPlaceData = ref({
      operation: "C",
      status: "A",
  });


  return {
    marketPlaceData,

  }
}
