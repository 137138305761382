import { fetchDevices, createDevices } from "@/services/apis";

export default {
  namespaced: true,
  state: {
    devices: [],
  },
  getters: {
    devicesOptions(state) {
      if (state.devices.length <= 0) {
        return [];
      }
      return state.devices.map((obj) => ({
        label: obj.price,
        value: obj.id,
      }));
    },
  },
  mutations: {
    SET_DEVICES_OPTIONS(state, data) {
      state.packages = data;
    },
  },
  actions: {
    // *===============================================---*
    // *--------- Device ---- ---------------------------------------*
    // *===============================================---*
    async fetchDevices(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchDevices, {
          params: queryParams,
        });
        ctx.commit("SET_DEVICES_OPTIONS", response.data.data);
        return response;
      } catch (error) {
        return error;
      }
    },
    async createDevice(ctx, params) {
      try {
        return await this.$axios.post(createDevices, params)
      } catch (error) {
        return error
      }
    },
  },
};
