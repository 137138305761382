<template>
  <b-row>
    <!--  name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="name"
        rules="required"
      >
        <b-form-group label="Name" label-for="name">
          <b-form-input
            id="name"
            v-model="marketPlaceData.name"
            :state="getValidationState(validationContext)"
            placeholder="name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!--  name Ar-->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="name ar"
        rules="required"
      >
        <b-form-group label="Name Arabic" label-for="nameAr">
          <b-form-input
            id="nameAr"
            v-model="marketPlaceData.nameAr"
            :state="getValidationState(validationContext)"
            placeholder="name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!--  description -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="description"
        rules="required"
      >
        <b-form-group label="Description" label-for="description">
          <b-form-input
            id="description"
            v-model="marketPlaceData.description"
            :state="getValidationState(validationContext)"
            placeholder="description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!--  description -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="description Arabic"
        rules="required"
      >
        <b-form-group label="Description Arabic" label-for="descriptionAr">
          <b-form-input
            id="descriptionAr"
            v-model="marketPlaceData.descriptionAr"
            :state="getValidationState(validationContext)"
            placeholder="description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!--  maxQuantity -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Max Quantity"
        rules="required"
      >
        <b-form-group label="Max Quantity" label-for="nameArabic">
          <b-form-input
            id="maxQuantity"
            v-model="marketPlaceData.maxQuantity"
            :state="getValidationState(validationContext)"
            placeholder="Max Quantity"
            type="number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!--  price -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="price"
        rules="required"
      >
        <b-form-group label="Price" label-for="nameArabic">
          <b-form-input
            id="price"
            v-model="marketPlaceData.price"
            :state="getValidationState(validationContext)"
            placeholder="price"
            type="number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- serial_number -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="serial_number"
        rules="required"
      >
        <b-form-group label="Serial No." label-for="serial_number">
          <b-form-input
            id="serial_number"
            v-model="marketPlaceData.serial_number"
            :state="getValidationState(validationContext)"
            placeholder="Serial No"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!-- Avatar -->
    <b-col sm="6">
      <b-media class="mb-2 align-items-end" :vertical-align="'end'">
        <template #aside>
          <b-avatar
            ref="previewEl"
            id="imageicon"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
          <b-avatar
            ref="previewEl"
            id="imageicon1"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
          <b-avatar
            ref="previewEl"
            id="imageicon2"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
          <b-avatar
            ref="previewEl"
            id="imageicon3"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
          <b-avatar
            ref="previewEl"
            id="imageicon4"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
        </template>
        <div class="d-flex mb-2">
          <b-button
            v-ripple.400="'rgba(255, 206, 38, 0.15)'"
            variant="primary"
            size="sm"
            @click="$refs.refInputEl.click()"
            id="uploadbtn"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              id="allImages"
              @input="uploadFile"
              multiple
            />

            <feather-icon icon="EditIcon" class="mr-50" />
            <span class="align-middle">Upload</span>
          </b-button>
          <b-button
            variant="outline-dark"
            size="sm"
            class="ml-1"
            @click="removeImages"
            id="removebtn"
          >
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span class="align-middle">Remove</span>
          </b-button>
        </div>
      </b-media>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BAvatar,
  BButton,
  BMedia,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import formValidation from "@core/comp-functions/forms/form-validation";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import S3 from "aws-s3";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import marketPlaceStore from "../marketPlaceStore";
//import AWS from "aws-sdk";

export default {
  computed: {
    config() {
      return {
        bucketName: "v2dev.fresh.sa",
        dirName: "packages/devices",
        region: "eu-west-1",
        accessKeyId: "AKIAX3FEA5SQUZ3UZ6XB",
        secretAccessKey: "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",
        s3Url: "https://s3.console.aws.amazon.com",
      };
    },
    s3upload() {
      return new AWS.S3({
        region: "eu-west-1",
        accessKeyId: "AKIAX3FEA5SQUZ3UZ6XB",
        secretAccessKey: "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",
      });
    },
    baseURL() {
      return "http://v2dev.fresh.sa";
    },
    S3Client() {
      return new S3(this.config);
    },
    newFileName() {
      return Math.random()
        .toString()
        .slice(2);
    },
    url() {
      return `${this.baseURL}/packages/devices/${this.newFileName}`;
    },
  },
  methods: {
    removeImages() {
      document.getElementById("allImages").value = "";
      document.getElementById("uploadbtn").style.display = "block";
      document.getElementById("removebtn").style.display = "none";
      var output = document.getElementById("imageicon");
      output.innerHTML = "";
      var output1 = document.getElementById("imageicon1");
      output1.innerHTML = "";
      var output2 = document.getElementById("imageicon2");
      output2.innerHTML = "";
      var output3 = document.getElementById("imageicon3");
      output3.innerHTML = "";
      var output4 = document.getElementById("imageicon4");
      output4.innerHTML = "";
    },
    uploadFile(files) {
      // Use toast

      let file = files.target.files;
      if (file.length < 3) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Less than two images are not allowrd",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return false;
      } else if (file.length > 5) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "More than five images are not allowrd",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return false;
      }
      file.forEach((element, i) => {
        // TODO: Upload it to S3
        // Get the URL for the file
        this.isLoading = true;
        this.S3Client.uploadFile(element, this.newFileName)
          .then((data) => {
            if (i === 0) {
              var output = document.getElementById("imageicon");
              output.innerHTML = "";
              var newoutput = document.createElement("img");
              newoutput.width = "90";
              newoutput.height = "90";
              newoutput.src = URL.createObjectURL(element);
              output.appendChild(newoutput);
              this.marketPlaceData.thumbnailPhotoUrl = data.location;
            } else if (i === 1) {
              var output = document.getElementById("imageicon1");
              output.innerHTML = "";
              var newoutput = document.createElement("img");
              newoutput.width = "90";
              newoutput.height = "90";
              newoutput.src = URL.createObjectURL(element);
              output.appendChild(newoutput);
              this.marketPlaceData.url1 = data.location;
            } else if (i === 2) {
              var output = document.getElementById("imageicon2");
              output.innerHTML = "";
              var newoutput = document.createElement("img");
              newoutput.width = "90";
              newoutput.height = "90";
              newoutput.src = URL.createObjectURL(element);
              output.appendChild(newoutput);
              this.marketPlaceData.url2 = data.location;
            } else if (i === 3) {
              var output = document.getElementById("imageicon3");
              output.innerHTML = "";
              var newoutput = document.createElement("img");
              newoutput.width = "90";
              newoutput.height = "90";
              newoutput.src = URL.createObjectURL(element);
              output.appendChild(newoutput);
              this.marketPlaceData.url3 = data.location;
            } else if (i === 4) {
              var output = document.getElementById("imageicon4");
              output.innerHTML = "";
              var newoutput = document.createElement("img");
              newoutput.width = "90";
              newoutput.height = "90";
              newoutput.src = URL.createObjectURL(element);
              output.appendChild(newoutput);
              this.marketPlaceData.url4 = data.location;
            }
            this.isLoading = false;
          })
          .finally(() => {});
      });

      //   this.s3upload
      //     .putObject({
      //       Key: this.newFileName,
      //       Bucket: "v2dev.fresh.sa",
      //       ContentType: "image/jpeg",
      //       Body: file,
      //       ACL: "public-read",
      //     })
      //     .promise().resolve(data).then((data) => {
      //         debugger;
      //         this.marketPlaceData.url1 = data;
      //     });
      document.getElementById("uploadbtn").style.display = "none";
      document.getElementById("removebtn").style.display = "block";
    },
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BAvatar,
    BButton,
    BMedia,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    marketPlaceData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { getValidationState } = formValidation();
    const refInputEl = ref(null);
    const previewEl = ref(null);
    let previewdara = ref("");
    const toast = useToast();

    return {
      //  Form
      getValidationState,
      refInputEl,
      previewEl,
      previewdara,
      toast,
    };
  },
};
</script>
<style scoped>
#removebtn {
  display: none;
}
.b-avatar.badge-light-primary {
  margin: 5px;
}
</style>
